.popup {
  &.popup-base {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity .5s;
    -moz-transition: opacity .5s;
    -ms-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition: opacity .5s;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 200;
    cursor: default;

    & > .popup-body {
      display: block;
      top: 15%;
      left: 30%;
      width: 30%;
      max-height: max-content;
      padding: 15px;
      border: 1px solid #C4C4C4;
      border-radius: 5px;
      background: #FFFFFF;
      z-index: 160;

      & > .popup-header {
        padding-bottom: 10px;
        border-bottom: 1px solid $color-border-light;
    
        & > .icon {
          width: 20px;
          height: 20px;
        }
      }
    
      & > .popup-content {
        padding: 10px 0;
      }
    }
  }

  &.popup-sidebar {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    width: 497px;
    padding: 24px;
    border-radius: 16px;
    background: $color-base-neutral;
    z-index: 107;

    & > .popup-body {
      display: block;

      & > .popup-header {
        padding-bottom: 10px;
    
        & > .icon {
          width: 20px;
          height: 20px;
        }
      }
    
      & > .popup-content {
        padding: 10px 0;
      }
    }
  }
}