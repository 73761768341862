
  .charts-canvas {
    position: absolute;

    &:focus-visible {
      outline: none;
    }

    .charts-canvas-links{
      width: 100%;
      height: 100%;
      position:absolute;
    }

    .node {
      border-color: #DEDEDE;

      &[data-focused]  {
        outline: none;
        border-color: #0CAEFF;
      }
    }
  }
