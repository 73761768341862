@import "@/themes/base.scss";
@import "@/themes/light/_variables.scss";
@import "@/themes/light/accordion.scss";
@import "@/themes/light/autocomplete.scss";
@import "@/themes/light/avatar.scss";
@import "@/themes/light/boxes.scss";
@import "@/themes/light/buttons.scss";
@import "@/themes/light/calendar.scss";
@import "@/themes/light/card.scss";
@import "@/themes/light/milestone.scss";
@import "@/themes/light/checkbox.scss";
@import "@/themes/light/editor.scss";
@import "@/themes/light/fields.scss";
@import "@/themes/light/hint.scss";
@import "@/themes/light/icons.scss";
@import "@/themes/light/input.scss";
@import "@/themes/light/navbar.scss";
@import "@/themes/light/path.scss";
@import "@/themes/light/popper.scss";
@import "@/themes/light/popups.scss";
@import "@/themes/light/progress.scss";
@import "@/themes/light/reminder.scss";
@import "@/themes/light/roadmap.scss";
@import "@/themes/light/select.scss";
@import "@/themes/light/sidebar.scss";
@import "@/themes/light/switch.scss";
@import "@/themes/light/tabs.scss";
@import "@/themes/light/textarea.scss";
@import "@/themes/light/timepicker.scss";
@import "@/themes/light/tracker.scss";
@import "@/themes/light/crumbs.scss";

html, body, #app {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-size: $font-text;
  font-family: $font-family;
  color: $color-base-dark;
}

.base-transparent     { background-color: transparent;         }
.base-neutral         { background-color: $color-base-neutral;         }
.base-light           { background-color: $color-base-light;           }
.base-soft            { background-color: $color-base-soft;            }
.base-soft-shadow     { background-color: $color-base-soft-shadow;     }
.base-highlight       { background-color: $color-base-highlight;       }
.base-highlight-slave { background-color: $color-base-highlight-slave; }
.base-selection       { background-color: $color-base-selection;       }
.base-dark            { background-color: $color-base-dark;            }
.base-denial          { background-color: $color-base-denial;          }


.hover-base-neutral   { &:hover { background-color: $color-base-neutral;   } }
.hover-base-light     { &:hover { background-color: $color-base-light;     } }
.hover-base-soft      { &:hover { background-color: $color-base-soft;      } }
.hover-base-highlight { &:hover { background-color: $color-base-highlight; } }
.hover-base-dark      { &:hover { background-color: $color-base-dark;      } }

.color-border-neutral         { border-color: $color-border-neutral;         }
.color-border-light           { border-color: $color-border-light;           }
.color-border-soft            { border-color: $color-border-soft;            }
.color-border-highlight       { border-color: $color-border-highlight;       }
.color-border-highlight-slave { border-color: $color-border-highlight-slave; }
.color-border-option          { border-color: $color-border-option;          }
.color-border-dark            { border-color: $color-border-dark;            }
.color-border-denial          { border-color: $color-border-denial;          }
.color-border-selection       { border-color: $color-border-selection;       }

.color-border-highlight-opacity-1 { border-color: rgba(12, 174, 255, 1); }
.color-border-highlight-opacity-0 { border-color: rgba(12, 174, 255, 0); }

.hover-border-light     { &:hover { border-color: $color-border-light;     } }
.hover-border-dark      { &:hover { border-color: $color-border-dark;      } }
.hover-border-soft      { &:hover { border-color: $color-border-soft;      } }
.hover-border-highlight { &:hover { border-color: $color-border-highlight; } }

.base-striped { background: repeating-linear-gradient(-45deg, $color-base-neutral 0 10px, $color-base-light 10px 20px); }

.box-shadow         { box-shadow: inset 0px -2px 0px $box-shadow; }
.toolbar-box-shadow { box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.25); }
.box-shadow-bottom  {
  -webkit-box-shadow: 0px 5px 8px 0px rgba(53, 59, 61, 0.2);
  -moz-box-shadow: 0px 5px 8px 0px rgba(53, 59, 61, 0.2);
  box-shadow: 0px 5px 8px 0px rgba(53, 59, 61, 0.2);
}
.box-shadow-card {
  -webkit-box-shadow: 0px 8px 6px -6px rgba(152, 152, 152, 0.16);
  -moz-box-shadow: 0px 8px 6px -6px rgba(152, 152, 152, 0.16);
  box-shadow: 0px 8px 6px -6px rgba(152, 152, 152, 0.16);
}
.box-shadow-top  {
  -webkit-box-shadow: 3px -3px 8px 0px rgba(53, 59, 61, 0.2);
  -moz-box-shadow: 3px -3px 8px 0px rgba(53, 59, 61, 0.2);
  box-shadow: 3px -3px 8px 0px rgba(53, 59, 61, 0.2);
}

.border-highlight-slave { border-color: $color-border-highlight-slave; }

.font-neutral   { color: $color-font-neutral;   }
.font-light     { color: $color-font-light;     }
.font-highlight { color: $color-font-highlight; }
.font-soft      { color: $color-font-soft;      }
.font-title     { color: $color-font-title;     }
.font-denial    { color: $color-font-denial;    }
.font-selection { color: $color-font-selection; }
.font-dark      { color: $color-font-dark;      }

.hover-font-neutral   { &:hover { color: $color-font-neutral;   } }
.hover-font-highlight { &:hover { color: $color-font-highlight; } }

.font-normal  { font-size: $font-text;                  }
.font-memo    { font-size: $font-text-secondary;        }
.font-header  { font-size: $font-text-header;           }
.font-section { font-size: $font-text-header-secondary; }
.font-avatar  { font-size: $font-text-avatar;           }

.font-type { font-family: $font-family; }

.border-radius-primary   { border-radius: $border-radius-primary; }
.border-radius-secondary { border-radius: $border-radius-secondary; }
.border-radius-header    { border-radius: $border-radius-header; }

.margin-x-truncate {
  &:first-of-type { margin-left: 0px;  }
  &:last-of-type  { margin-right: 0px; }
}

.margin-y-truncate {
  &:first-of-type { margin-top: 0px;  }
  &:last-of-type  { margin-bottom: 0px; }
}




.base-success-soft {background: #D7FEEF;}
.base-denial-soft {background: #FFD1CE;}

.color-success-soft { background: $color-success-soft; }
.color-neutral-soft { background: $color-neutral-soft; }



.hover-neutral      { &:hover { background: $color-neutral;      } }
.hover-neutral-soft { &:hover { background: $color-neutral-soft; } }
.hover-base-light   { &:hover { background: $color-base-light;   } }
.hover-base-neutral { &:hover { background: $color-base-neutral; } }

.hover-font-light { &:hover { color: $color-font-light; } }
.hover-font-dark  { &:hover { color: $color-font-dark;  } }

.barrier {
  background: repeating-linear-gradient(125deg, $color-base-light, $color-base-neutral, $color-base-light 30px);
}

.short-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.short-string {
  overflow: hidden;
  text-overflow: ellipsis;
  // word-break: break-all;
  display: -webkit-box;
  max-height: 40px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.short-text {
  overflow: hidden;
  text-overflow: ellipsis;
  // word-break: break-all;
  display: -webkit-box;
  max-height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.scrollbar {
  &::-webkit-scrollbar             { width: 4px; height: 4px;                   }
  &::-webkit-scrollbar-track       { background: transparent;                   }
  &::-webkit-scrollbar-thumb       { background: #DEDEDE; border-radius: 2px; }
  &::-webkit-scrollbar-thumb:hover { background: rgba(35, 45, 49, 0.25);      }
}

.scrollbar-disable {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar { width: 0; height: 0;}
}



p {
  margin: 0 0 5px 0;
}


hr {
  display: flex;
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  margin-bottom: 15px;
  border: none;
  background: $color-border-light;
  
  &.dark {
    background: $color-border-dark;
  }

  &.light {
    background: $color-border-light;
  }
}

.transition-25   { transition: all 25ms   linear; }
.transition-50   { transition: all 50ms   linear; }
.transition-75   { transition: all 75ms   linear; }
.transition-100  { transition: all 100ms  linear; }
.transition-250  { transition: all 250ms  linear; }
.transition-1000 { transition: all 1000ms linear; }
.transition-3000 { transition: all 3000ms linear; }