.sidebar {
  width: 200px;

  &.active {
    width: auto;
    min-width: 20%;
  }

  .list {
    &::-webkit-scrollbar             { width: 4px;            }
    &::-webkit-scrollbar-track       { background: #f1f1f1; }
    &::-webkit-scrollbar-thumb       { background: #E5E5E5; }
    &::-webkit-scrollbar-thumb:hover { background: #AAAAAA; }

    height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: $color-base-neutral;

    .options {
      .option {
        display: block;
        margin: 2px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 4px;
        background-size: cover;
        background-color: $color-base-neutral;
        color: $color-font-light;
  
        &:hover {
          background-color: $color-neutral;
          color: $color-font-neutral;
        }
      }
    }
  }
}