.avatar-upload-root{--fixed-fade-z-index: 999;--fixed-main-z-index: 1000}
.avatar-upload-root img{max-width:unset;max-height:unset}
.avatar-upload-root-fixed{position:fixed;bottom:0;left:0;right:0;top:0;justify-content:center;display:flex;align-items:center;z-index: 200;}
.avatar-upload-fade{position:fixed;bottom:0;left:0;right:0;top:0;background:rgba(0,0,0,.3);z-index:var(--fixed-z-index)}
.avatar-upload{border-radius:10px;background-color:#fff;padding:5px 15px;user-select:none;z-index:var(--fixed-main-z-index)}
.avatar-upload .border-3-white{border:3px solid #fff}
.avatar-upload-fixed{position:fixed}
.avatar-upload-header{display:flex;justify-content:space-between;align-items:center;height:48px;font-size:16px;font-weight:700}
.avatar-upload-header .avatar-upload-close{font-size:18px;cursor:pointer}
.avatar-upload-main{padding:10px;display:flex;justify-content:center}
.avatar-upload-main .edit-fade{position:absolute;top:0;left:0;width:100%;height:100%;background-color:#00000080;z-index:3}
.avatar-upload-edit{position:relative;cursor:move;margin-right:10px;overflow:hidden;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);background-repeat:repeat}
.avatar-upload-edit .edit-select{position:absolute;border-radius:50%;cursor:move;z-index:3}
.avatar-upload-edit .edit-select .select-zoom-point{position:absolute;width:10px;height:10px;background:#fff;border-radius:50%;left:84%;top:84%;cursor:se-resize}
.avatar-upload-edit .edit-select .edit-selcet-border{position:absolute;display:block;width:calc(100% + 4px);height:calc(100% + 4px);border-radius:50%;box-sizing:border-box;top:-2px;left:-2px}
.avatar-upload-edit .edit-select .edit-selcet-img-box{overflow:hidden;position:absolute;display:block;width:100%;height:100%;border-radius:50%}
.avatar-upload-edit .edit-select .edit-select-img{user-select:none;display:block;position:absolute}
.edit-bg{user-select:none;display:block;position:absolute;z-index:2;cursor:move}
.avatar-upload-preview{display:flex;flex-direction:column;justify-content:space-around;align-items:center;background:#f2f2f2;padding:0 10px;margin-bottom:20px;font-size:14px}
.avatar-upload-preview .preview-radius{border-radius:50%;overflow:hidden;position:relative}
.avatar-upload-preview .preview-radius>img{position:absolute}
.avatar-upload-preview .preview-square{overflow:hidden;position:relative}
.avatar-upload-preview .preview-square>img{position:absolute}
.avatar-upload-operation{display:flex;justify-content:space-between;align-items:center;font-size:12px;color:red;height:20px;margin-right:10px}
.upload-operation-close{display:flex;align-items:center}
.icon-rotate{color:red;fill:currentColor}
.avatar-upload-actions{display:flex;justify-content:center;align-items:center;margin:0 10px}
.avatar-upload-actions .avatar-button{display:flex;overflow:hidden;margin:10px;padding:8px 12px;cursor:pointer;user-select:none;transition:all .15s linear;text-align:center;white-space:nowrap;text-decoration:none!important;text-transform:none;text-transform:capitalize;color:#fff;border:0 none;border-radius:10px;font-size:14px;font-weight:500;line-height:1.3;-webkit-appearance:none;-moz-appearance:none;appearance:none;justify-content:center;align-items:center;flex:0 0 160px;box-shadow:2px 5px 10px #e4e4e4}
.avatar-upload-actions .avatar-button:hover{transition:all .15s linear;opacity:.85}
.avatar-upload-actions .avatar-button:active{transition:all .15s linear;opacity:.75}
.avatar-upload-actions .avatar-button:focus{outline:1px dotted #959595;outline-offset:-4px}
.avatar-upload-actions .avatar-button.-regular{color:#202129;background-color:#f2f2f2}
.avatar-upload-actions .avatar-button.-regular:hover{color:#202129;background-color:#e1e2e2;opacity:1}
.avatar-upload-actions .avatar-button.-regular:active{background-color:#d5d6d6;opacity:1}
.avatar-upload-actions .avatar-button.-salmon{color:#fff;background:#F32C52}

.avatar {
 	&.value {
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      min-width: 54px;
      height: 54px;
      color: #FFFFFF;
      text-transform: uppercase;
      border-radius: 20px;
    }
  }

  &.avatar-card {
    .item {
      display: flex;
      align-items: center;
      height: 29px;
      border-radius: 10px;
      font-size: 14px;

      .user {
        width: 22px;
        height: 22px;
        text-transform: uppercase;
        line-height: 22px;
        justify-content: center;
        display: flex;
        border: 1px solid transparent;
        border-radius: 8px;
        margin: 2px;
      } 
    }
  }

  &.list {
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      min-width: 38px;
      height: 38px;
      line-height: 40px;
      color: #FFFFFF;
      text-transform: uppercase;
      border-radius: 25px;
      background-color: $color-base-highlight;

      &.locked {
        background-color: $color-base-highlight-slave;
      }
    }
  }
}