.navbar {
  ul {
    padding-inline-start: 0px;
  
    & > li.tab {
      list-style: none;
      position: relative;
  
      div.item {
        padding: 10px;
        text-align: center;
        font-size: $font-text;
        color: $color-font-dark;
      }
  
      &.active > div.item {
        background: $color-base-light;
        cursor: default;
      }
  
      &:hover > div.item {
        background: $color-base-light;
      }
    }
  }

  ul.dropdown {
    background: $color-base-neutral;
    border: 1px solid $color-border-light;
    padding: 10px;

    div.item {
      padding: 10px;
      text-align: center;
      font-size: $font-text;
      color: $color-font-dark;
    }
  }
}