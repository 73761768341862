
  .overview {
    right: 10px;
    bottom: 10px;

    position: absolute;
    border  : 10px solid #FFFFFF;

    transform       : scale(0.1, 0.1);
    transform-origin: bottom right;
  }

  .viewport {
    border  : 5px solid #353B3D;
    position: absolute;
  }
