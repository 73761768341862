/* DISPLAY */
.d-none-no-important { display: none; }

.d-none    { display: none !important; }
.d-xs-none { @media (max-width: 576px)  { display: none; }}
.d-sm-none { @media (min-width: 576px)  { display: none; }}
.d-md-none { @media (min-width: 768px)  { display: none; }}
.d-lg-none { @media (min-width: 992px)  { display: none; }}
.d-xl-none { @media (min-width: 1200px) { display: none; }}

.d-block    { display: block; }
.d-xs-block { @media (max-width: 576px)  { display: block; }}
.d-sm-block { @media (min-width: 576px)  { display: block; }}
.d-md-block { @media (min-width: 768px)  { display: block; }}
.d-lg-block { @media (min-width: 992px)  { display: block; }}
.d-xl-block { @media (min-width: 1200px) { display: block; }}

.d-flex    { display: flex; }
.d-xs-flex { @media (max-width: 576px)  { display: flex; }}
.d-sm-flex { @media (min-width: 576px)  { display: flex; }}
.d-md-flex { @media (min-width: 768px)  { display: flex; }}
.d-lg-flex { @media (min-width: 992px)  { display: flex; }}
.d-xl-flex { @media (min-width: 1200px) { display: flex; }}

.d-inline       { display: inline;       }
.d-inline-block { display: inline-block; }
.d-inline-flex  { display: inline-flex;  }

/* FLEX OPTIONS */
.flex-column { flex-direction: column; }
.flex-row    { flex-direction: row; }

.flex-wrap   { flex-wrap: wrap;   }
.flex-nowrap { flex-wrap: nowrap; }

.flex-nogrow { flex-grow: 0; }
.flex-grow   { flex-grow: 1; }

.justify-content-between { justify-content: space-between; }
.justify-content-end     { justify-content: flex-end;      }
.justify-content-start   { justify-content: flex-start;    }
.justify-content-center  { justify-content: center;        }

.justify-self-center { justify-self: center; }
.justify-self-end    { justify-self: end;    }

.align-items-center { align-items: center;     }
.align-items-end    { align-items: end;        }
.align-items-start  { align-items: flex-start; }
.align-self-center  { align-self: center;      }

/* POSITION */
.p-absolute { position: absolute; }
.p-relative { position: relative; }
.p-static   { position: static;   }
.p-sticky   { position: sticky;   }
.p-fixed    { position: fixed;    }

/* WIDTH */
.w-332   { width: 332%;  }
.w-333   { width: 333%;  }
.w-300   { width: 300%;  }
.w-100   { width: 100%;  }
.w-85    { width: 85%;   }
.w-80    { width: 80%;   }
.w-75    { width: 75%;   }
.w-70    { width: 70%;   }
.w-65    { width: 65%;   }
.w-60    { width: 60%;   }
.w-50    { width: 50%;   }
.w-40    { width: 40%;   }
.w-35    { width: 35%;   }
.w-33    { width: 33%;   }
.w-30    { width: 30%;   }
.w-25    { width: 25%;   }
.w-20    { width: 20%;   }
.w-15    { width: 15%;   }
.w-14    { width: 14%;   }
.w-10    { width: 10%;   }
.w-0px   { width: 0px;   }
.w-1px   { width: 1px;   }
.w-3px   { width: 3px;   }
.w-5px   { width: 5px;   }
.w-10px  { width: 10px;  }
.w-18px  { width: 18px;  }
.w-20px  { width: 20px;  }
.w-24px  { width: 24px;  }
.w-32px  { width: 32px;  }
.w-34px  { width: 34px;  }
.w-40px  { width: 40px;  }
.w-60px  { width: 60px;  }
.w-80px  { width: 80px;  }
.w-100px { width: 100px; }
.w-120px { width: 120px; }
.w-125px { width: 125px; }
.w-150px { width: 150px; }
.w-200px { width: 200px; }
.w-218px { width: 218px; }
.w-222px { width: 222px; }
.w-250px { width: 250px; }
.w-300px { width: 300px; }
.w-600px { width: 600px; }
.w-800px { width: 800px; }

.w-75-imp { width: 75% !important; }

.w-auto        { width: auto !important;        }
.w-inherit     { width: inherit !important;     }
.w-initial     { width: initial !important;     }
.w-fit-content { width: fit-content !important; }

.w-20-20px  { width: calc(20% - 20px);  }
.w-100-30px { width: calc(100% - 30px); }

.w-75--89px   { width: calc(75% - 89px);   }
.w-75--104px  { width: calc(75% - 104px);  }
.w-100--8px   { width: calc(100% - 8px);   }
.w-100--16px  { width: calc(100% - 16px);  }
.w-100--28px  { width: calc(100% - 28px);  }
.w-100--32px  { width: calc(100% - 32px);  }
.w-100--76px  { width: calc(100% - 76px);  }
.w-100--92px  { width: calc(100% - 92px);  }
.w-100--112px { width: calc(100% - 112px); }
.w-100--116px { width: calc(100% - 116px); }
.w-100--134px { width: calc(100% - 134px); }
.w-100--150px { width: calc(100% - 150px); }
.w-100--174px { width: calc(100% - 174px); }

.min-w-10     { min-width: 10%;    }
.min-w-15     { min-width: 15%;    }
.min-w-20     { min-width: 20%;    }
.min-w-30     { min-width: 30%;    }
.min-w-100    { min-width: 100%;   }
.min-w-5px    { min-width: 5px;    }
.min-w-34px   { min-width: 34px;   }
.min-w-60px   { min-width: 60px;   }
.min-w-80px   { min-width: 80px;   }
.min-w-120px  { min-width: 120px;  }
.min-w-125px  { min-width: 125px;  }
.min-w-150px  { min-width: 150px;  }
.min-w-200px  { min-width: 200px;  }
.min-w-202px  { min-width: 202px;  }
.min-w-220px  { min-width: 220px;  }
.min-w-222px  { min-width: 222px;  }
.min-w-242px  { min-width: 242px;  }
.min-w-250px  { min-width: 250px;  }
.min-w-300px  { min-width: 300px;  }
.max-w-15     { max-width: 15%;    }
.max-w-25     { max-width: 25%;    }
.max-w-50     { max-width: 50%;    }
.max-w-100    { max-width: 100%;   }
.max-w-34px   { max-width: 34px;   }
.max-w-60px   { max-width: 60px;   }
.max-w-80px   { max-width: 80px;   }
.max-w-100px  { max-width: 100px;  }
.max-w-125px  { max-width: 125px;  }
.max-w-150px  { max-width: 150px;  }
.max-w-200px  { max-width: 200px;  }
.max-w-202px  { max-width: 202px;  }
.max-w-220px  { max-width: 220px;  }
.max-w-222px  { max-width: 222px;  }
.max-w-242px  { max-width: 242px;  }
.max-w-290px  { max-width: 290px;  }
.max-w-300px  { max-width: 300px;  }
.max-w-400px  { max-width: 400px;  }
.max-w-800px  { max-width: 800px;  }
.max-w-1350px { max-width: 1350px; }

.w-xs-100 { @media (max-width: 576px)  { width: 100%; }}
.w-sm-100 { @media (max-width: 576px)  { width: 100%; }}
.w-md-100 { @media (max-width: 768px)  { width: 100%; }}
.w-lg-100 { @media (max-width: 992px)  { width: 100%; }}
.w-xl-100 { @media (max-width: 1200px) { width: 100%; }}

/* HEIGHT */
.h-fit-content { height: fit-content;  }

.h-inherit { height: inherit; }

.h-0 { height: 0; }

.h-100   { height: 100%;  }
.h-60    { height: 60%;   }
.h-50    { height: 50%;   }
.h-33    { height: 33%;   }
.h-25    { height: 25%;   }
.h-auto  { height: auto;  }
.h-1px   { height: 1px;   }
.h-5px   { height: 5px;   }
.h-10px  { height: 10px;  }
.h-11px  { height: 11px;  }
.h-18px  { height: 18px;  }
.h-20px  { height: 20px;  }
.h-24px  { height: 24px;  }
.h-28px  { height: 28px;  }
.h-30px  { height: 30px;  }
.h-32px  { height: 32px;  }
.h-40px  { height: 40px;  }
.h-44px  { height: 44px;  }
.h-48px  { height: 48px;  }
.h-52px  { height: 52px;  }
.h-60px  { height: 60px;  }
.h-70px  { height: 70px;  }
.h-80px  { height: 80px;  }
.h-84px  { height: 84px;  }
.h-91px  { height: 91px;  }
.h-100px { height: 100px; }
.h-150px { height: 150px; }
.h-170px { height: 170px; }
.h-200px { height: 200px; }
.h-250px { height: 250px; }
.h-300px { height: 300px; }
.h-500px { height: 500px; }
.h-800px { height: 800px; }

.h-100-40px   { height: calc(100% + 40px);  }
.h-100--10px  { height: calc(100% - 10px);  }
.h-100--30px  { height: calc(100% - 30px);  }
.h-100--40px  { height: calc(100% - 40px);  }
.h-100--166px { height: calc(100% - 166px); }

.min-h-inherit    { min-height: inherit;            }
.min-h-30px       { min-height: 30px;               }
.min-h-40px       { min-height: 40px;               }
.min-h-60px       { min-height: 60px;               }
.min-h-80px       { min-height: 80px;               }
.min-h-100px      { min-height: 100px;              }
.min-h-300px      { min-height: 300px;              }
.min-h-400px      { min-height: 400px;              }
.max-h-100        { max-height: 100%;               }
.max-h-40px       { max-height: 40px;               }
.max-h-60px       { max-height: 60px;               }
.max-h-80px       { max-height: 80px;               }
.max-h-100px      { max-height: 100px;              }
.max-h-200px      { max-height: 200px;              }
.max-h-400px      { max-height: 400px;              }
.max-h-500px      { max-height: 500px;              }
.min-h-100-80px   { min-height: calc(100% - 80px);  }
.min-h-100--60px  { min-height: calc(100% - 60px);  }
.min-h-100--100px { min-height: calc(100% - 100px); }

/* POSITION */
.t-0 { top: 0px;    }
.l-0 { left: 0px;   }
.r-0 { right: 0px;  }
.b-0 { bottom: 0px; }

.t-50 { top: 50%;    }
.l-50 { left: 50%;   }
.r-50 { right: 50%;  }
.b-50 { bottom: 50%; }

.t-4px   { top: 4px;   }
.t-5px   { top: 5px;   }
.t-8px   { top: 8px;   }
.t-9px   { top: 9px;   }
.t-10px  { top: 10px;  }
.t-17px  { top: 17px;  }
.t-20px  { top: 20px;  }
.t-30px  { top: 30px;  }
.t-39px  { top: 39px;  }
.t-47px  { top: 47px;  }
.t-60px  { top: 60px;  }
.t-80px  { top: 80px;  }
.t--2px  { top: -2px;  }
.t--7px  { top: -7px;  }
.t--10px { top: -10px; }
.t--13px { top: -13px; }

.l-100    { left: 100%;   }
.l-4px    { left: 4px;    }
.l-5px    { left: 5px;    }
.l-6px    { left: 6px;    }
.l-8px    { left: 8px;    }
.l-9px    { left: 9px;    }
.l-10px   { left: 10px;   }
.l-17px   { left: 17px;   }
.l-20px   { left: 20px;   }
.l-80px   { left: 80px;   }
.l-120px  { left: 120px;  }
.l-200px  { left: 200px;  }
.l-250px  { left: 250px;  }
.l--100px { left: -100px; }

.r-30px-imp { right: 30px !important; }
.r-67px-imp { right: 67px !important; }
.r-100   { right: 100%;  }
.r-3px   { right: 3px;   }
.r-5px   { right: 5px;   }
.r-8px   { right: 8px;   }
.r-10px  { right: 10px;  }
.r-16px  { right: 16px;  }
.r-17px  { right: 17px;  }
.r-20px  { right: 20px;  }
.r-80px  { right: 80px;  }
.r--2px  { right: -2px;  }
.r--7px  { right: -7px;  }
.r--10px { right: -10px; }
.r--13px { right: -13px; }
.r--17px { right: -17px; }
.r--20px { right: -20px; }

.b-0     { bottom: 0px;  }
.b-5px   { bottom: 5px;  }
.b-10px  { bottom: 10px; }
.b-17px  { bottom: 17px; }
.b-20px  { bottom: 20px; }
.b-70px  { bottom: 70px; }
.b-80px  { bottom: 80px; }
.b--7px  { bottom: -7px; }
.b--20px { bottom: -20px; }

/* PADDING */
.p-0     { padding: 0;                              }
.p-4     { padding: 4px;                            }
.p-5     { padding: 5px;                            }
.p-8     { padding: 8px;                            }
.p-12    { padding: 12px;                           }
.p-15    { padding: 15px;                           }
.p-16    { padding: 16px;                           }
.p-18    { padding: 18px;                           }
.p-20    { padding: 20px;                           }
.p-28    { padding: 28px;                           }
.p-30    { padding: 30px;                           }
.pl-0    { padding-left: 0;                         }
.pl-2    { padding-left: 2px;                       }
.pl-4    { padding-left: 4px;                       }
.pl-8    { padding-left: 8px;                       }
.pl-10   { padding-left: 10px;                      }
.pl-15   { padding-left: 15px;                      }
.pl-20   { padding-left: 20px;                      }
.pl-24   { padding-left: 24px;                      }
.pl-32   { padding-left: 32px;                      }
.pl-42   { padding-left: 42px;                      }
.pl-60   { padding-left: 60px;                      }
.pl-80   { padding-left: 80px;                      }
.pr-0    { padding-right: 0;                        }
.pr-2    { padding-right: 2px;                      }
.pr-8    { padding-right: 8px;                      }
.pr-16   { padding-right: 16px;                     }
.pr-20   { padding-right: 20px;                     }
.pr-25   { padding-right: 25px;                     }
.pt-0    { padding-top: 0;                          }
.pt-5    { padding-top: 5px;                        }
.pt-8    { padding-top: 8px;                        }
.pt-10   { padding-top: 10px;                       }
.pt-16   { padding-top: 16px;                       }
.pt-44   { padding-top: 44px;                       }
.pt-60   { padding-top: 60px;                       }
.pt-76   { padding-top: 76px;                       }
.pt-84   { padding-top: 84px;                       }
.pt-94   { padding-top: 94px;                       }
.pb-0    { padding-bottom: 0;                       }
.pb-5    { padding-bottom: 5px;                     }
.pb-8    { padding-bottom: 8px;                     }
.pb-10   { padding-bottom: 10px;                    }
.pb-16   { padding-bottom: 16px;                    }
.px-0    { padding-left: 0; padding-right: 0;       }
.px-4    { padding-left: 4px; padding-right: 4px;   }
.px-8    { padding-left: 8px; padding-right: 8px;   }
.px-10   { padding-left: 10px; padding-right: 10px; }
.px-16   { padding-left: 16px; padding-right: 16px; }
.px-20   { padding-left: 20px; padding-right: 20px; }
.px-27   { padding-left: 27px; padding-right: 27px; }
.py-0    { padding-top: 0; padding-bottom: 0;       }
.py-4    { padding-top: 4px; padding-bottom: 4px;   }
.py-5    { padding-top: 5px; padding-bottom: 5px;   }
.py-8    { padding-top: 8px; padding-bottom: 8px;   }
.py-10   { padding-top: 10px; padding-bottom: 10px; }
.py-12   { padding-top: 12px; padding-bottom: 12px; }
.py-16   { padding-top: 16px; padding-bottom: 16px; }
.py-18   { padding-top: 18px; padding-bottom: 18px; }
.p-5-0   { padding: 5px 0px;                        }
.p-0-5   { padding: 0px 5px;                        }
.p-10    { padding: 10px;                           }
.p-10-0  { padding: 10px 0px;                       }
.p-0-10  { padding: 0px 10px;                       }
.p-25-0  { padding: 25px 0px;                       }
.p-22-12 { padding: 22px 12px;                      }

/* MARGIN */
.m-auto  { margin: auto;                          }
.m-0     { margin: 0;                             }
.m-4     { margin: 5px;                           }
.m-5     { margin: 5px;                           }
.m-8     { margin: 8px;                           }
.m-10    { margin: 10px;                          }
.m-16    { margin: 16px;                          }
.ml-auto { margin-left: auto;                     }
.ml-0    { margin-left: 0;                        }
.ml-2    { margin-left: 2px;                      }
.ml-5    { margin-left: 5px;                      }
.ml-7    { margin-left: 7px;                      }
.ml-8    { margin-left: 8px;                      }
.ml-10   { margin-left: 10px;                     }
.ml-16   { margin-left: 16px;                     }
.ml-20   { margin-left: 20px;                     }
.ml-27   { margin-left: 27px;                     }
.ml--1   { margin-left: -1px;                     }
.ml--6   { margin-left: -6px;                     }
.ml--9   { margin-left: -9px;                     }
.mr-0    { margin-right: 0;                       }
.mr-2    { margin-right: 2px;                     }
.mr-4    { margin-right: 4px;                     }
.mr-5    { margin-right: 5px;                     }
.mr-8    { margin-right: 8px;                     }
.mr-10   { margin-right: 10px;                    }
.mr-16   { margin-right: 16px;                    }
.mr-20   { margin-right: 20px;                    }
.mt-auto { margin-top: auto;                      }
.mt-0    { margin-top: 0;                         }
.mt-5    { margin-top: 5px;                       }
.mt-8    { margin-top: 8px;                       }
.mt-10   { margin-top: 10px;                      }
.mt-15   { margin-top: 15px;                      }
.mt-16   { margin-top: 16px;                      }
.mt--1   { margin-top: -1px;                      }
.mt--10  { margin-top: -10px;                     }
.mt--17  { margin-top: -17px;                     }
.mt--518 { margin-top: -518px;                    }
.mt-20   { margin-top: 20px;                      }
.mt-32   { margin-top: 32px;                      }
.mb-0    { margin-bottom: 0;                      }
.mb-4    { margin-bottom: 4px;                    }
.mb-5    { margin-bottom: 5px;                    }
.mb-8    { margin-bottom: 8px;                    }
.mb-10   { margin-bottom: 10px;                   }
.mb-15   { margin-bottom: 15px;                   }
.mb-16   { margin-bottom: 16px;                   }
.mb-20   { margin-bottom: 20px;                   }
.mb-30   { margin-bottom: 30px;                   }
.mb-32   { margin-bottom: 32px;                   }
.mb--10  { margin-bottom: -10px;                  }
.my-auto { margin-top: auto; margin-bottom: auto; }
.mx-0    { margin-left: 0px; margin-right: 0px;   }
.mx-4    { margin-left: 4px; margin-right: 4px;   }
.mx-5    { margin-left: 5px; margin-right: 5px;   }
.mx-8    { margin-left: 8px; margin-right: 8px;   }
.mx-10   { margin-left: 10px; margin-right: 10px; }
.mx-16   { margin-left: 16px; margin-right: 16px; }
.mx-20   { margin-left: 20px; margin-right: 20px; }
.mx-27   { margin-left: 27px; margin-right: 27px; }
.my-0    { margin-top: 0px; margin-bottom: 0px;   }
.my-1    { margin-top: 1px; margin-bottom: 1px;   }
.my-3    { margin-top: 3px; margin-bottom: 3px;   }
.my-4    { margin-top: 4px; margin-bottom: 4px;   }
.my-5    { margin-top: 5px; margin-bottom: 5px;   }
.my-8    { margin-top: 8px; margin-bottom: 8px;   }
.my-10   { margin-top: 10px; margin-bottom: 10px; }
.my-16   { margin-top: 16px; margin-bottom: 16px; }
.my-20   { margin-top: 20px; margin-bottom: 20px; }

.ml--1px  { margin-left: -1px;  }
.ml--100p { margin-left: -100%; }
.ml--116p { margin-left: -116%; }
.ml--117p { margin-left: -117%; }
.ml--200p { margin-left: -200%; }
.ml--232p { margin-left: -232%; }

.mx-0-i { margin-left: 0px !important; margin-right: 0px !important; }

/* BORDER */
.border-none              { border: none !important;          }
.border-1                 { border: 1px solid;                }
.border-1-dashed          { border: 1px dashed;               }
.border-bottom-1          { border-bottom: 1px solid;         }
.border-bottom-2          { border-bottom: 2px solid;         }
.border-bottom-1-dashed   { border-bottom: 1px dashed;        }
.border-top-1             { border-top: 1px solid;            }
.border-top-1-dashed      { border-top: 1px dashed;           }
.border-right-1           { border-right: 1px solid;          }
.border-right-1-dashed    { border-right: 1px dashed;         }
.border-left-1            { border-left: 1px solid;           }
.border-left-1-dashed     { border-left: 1px dashed;          }
.border-left-3            { border-left: 3px solid;           }
.border-2                 { border: 2px solid;                }
.border-radius-4          { border-radius: 4px;               }
.border-radius-5          { border-radius: 5px;               }
.border-radius-6          { border-radius: 6px;               }
.border-radius-7          { border-radius: 7px;               }
.border-radius-8          { border-radius: 8px;               }
.border-radius-12         { border-radius: 12px;              }
.border-radius-16         { border-radius: 16px;              }
.border-radius-25         { border-radius: 25px;              }
.border-radius-100        { border-radius: 100px;             }
.border-radius-16-16-0-0  { border-radius: 16px 16px 0px 0px; }
.border-radius-0-16-16-0  { border-radius: 0px 16px 16px 0px; }
.border-radius-8-0-0-8    { border-radius: 8px 0px 0px 8px;   }
.border-radius-8-0-8-8    { border-radius: 8px 0px 8px 8px;   }
.border-radius-0-8-8-0    { border-radius: 0px 8px 8px 0px;   }
.border-radius-50-percent { border-radius: 50%;               }
.border-color-transparent { border-color: transparent;        }

/* CURSOR */
.c-pointer    { cursor: pointer;    }
.c-default    { cursor: default;    }
.c-move       { cursor: move;       }
.c-col-resize { cursor: col-resize; }
.c-crosshair  { cursor: crosshair;  }

/* OVERFLOW */
.overflow-auto   { overflow: auto;   }
.overflow-hidden { overflow: hidden; }
.overflow-scroll { overflow: scroll; }

.overflow-y-hidden { overflow-y: hidden; }
.overflow-y-scroll { overflow-y: scroll; }

.overflow-x-hidden { overflow-x: hidden; }
.overflow-x-scroll { overflow-x: scroll; }

.overflow-wrap   { overflow-wrap: anywhere; }

/* TEXT OPTIONS */
.text-center          { text-align: center;         }
.text-right           { text-align: right;          }
.text-justify         { text-align: justify;        }
.font-italic          { font-style: italic;         }
.font-bold            { font-weight: bold;          }
.text-capitalize      { text-transform: capitalize; }
.text-uppercase       { text-transform: uppercase;  }
.text-nowrap          { white-space: nowrap;        }
// .text-nowrap          { text-wrap: nowrap;          }
.text-decoration-none { text-decoration: none;      }
.word-break-all       { word-break: break-all;      }
.word-break           { word-break: break-word;     }

.lh-14 { line-height: 14px; }
.lh-16 { line-height: 16px; }
.lh-18 { line-height: 18px; }
.lh-20 { line-height: 20px; }
.lh-24 { line-height: 24px; }
.lh-27 { line-height: 27px; }
.lh-28 { line-height: 28px; }
.lh-32 { line-height: 32px; }
.lh-36 { line-height: 36px; }
.lh-54 { line-height: 54px; }
.lh-56 { line-height: 56px; }

.font-weight-500 { font-weight: 500; }

/* OPACITY */
.opacity-0   { opacity: 0;   }
.opacity-20  { opacity: 0.2; }
.opacity-40  { opacity: 0.4; }
.opacity-50  { opacity: 0.5; }
.opacity-60  { opacity: 0.6; }
.opacity-80  { opacity: 0.8; }
.opacity-100 { opacity: 1;   }

/* TRANSFORM */
.transform-scale-0-85 { transform: scale(0.85); }
.transform-scale-0-75 { transform: scale(0.75); }
.transform-scale-0-65 { transform: scale(0.65); }

/* VIVIBILITY */
.visibility-hidden  { visibility: hidden;  }
.visibility-visible { visibility: visible; }

/* IMAGE */
.object-fit-cover { object-fit: cover; }

/* SCROLL */
.scroll-behavior-smooth { scroll-behavior: smooth; }

/* Z-INDEX */
.z--1  { z-index: -1;  }
.z-10  { z-index: 10;  }
.z-20  { z-index: 20;  }
.z-30  { z-index: 30;  }
.z-40  { z-index: 40;  }
.z-50  { z-index: 50;  }
.z-101 { z-index: 101; }
.z-102 { z-index: 102; }
.z-104 { z-index: 104; }
.z-105 { z-index: 105; }
.z-106 { z-index: 106; }
.z-107 { z-index: 107; }
.z-109 { z-index: 109; }
.z-110 { z-index: 110; }