.vue-tooltip {
  display: none;
  max-width: 300px;
  justify-content: space-between;
  background: $color-neutral;
  color: $color-font-neutral;
  font-weight: bold;
  padding: 10px;
  font-size: $font-text;
  border-radius: 4px;
  z-index: 150;
  position: absolute;

  &[data-show] {
    display: flex;
  }

  .vue-arrow,
  .vue-arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .next {
    display: flex;
    cursor: pointer;
  }

  .vue-arrow {
    visibility: hidden;
  }
  
  .vue-arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
  
  &[data-popper-placement^='top'] > .vue-arrow {
    bottom: -4px;
  }
  
  &[data-popper-placement^='bottom'] > .vue-arrow {
    top: -4px;
  }
  
  &[data-popper-placement^='left'] > .vue-arrow {
    right: -4px;
  }
  
  &[data-popper-placement^='right'] > .vue-arrow {
    left: -4px;
  }
}
