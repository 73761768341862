
  .landing {
    section.team {
      min-height: 540px;
      padding: 10px;

      .profile {
        cursor: pointer;
        margin-top: 30px;

        .photo {
          min-height: 400px;
          min-width: 30%;
          margin-right: 20px;
        }

        .details {
          .name {
            font-size: 200%;
          }

          .text {
            margin-top: 40px;
          }

          .mark {
            height: 40px;
            margin-top: 40px;
          }
        }
      }
    }

    section.team {
      [data-profile="0"] {
        .photo {
          background: url("@/themes/light/images/landing/team-oleg.png") no-repeat center center;
          background-size: contain;
        }

        .details .mark {
          background: url("@/themes/light/images/landing/team-profile-1.png") no-repeat left center;
        }
      }

      [data-profile="1"] {
        .photo {
          background: url("@/themes/light/images/landing/team-alex.png") no-repeat center center;
          background-size: contain;
        }

        .details .mark {
          background: url("@/themes/light/images/landing/team-profile-2.png") no-repeat left center;
        }
      }
    }

    @media (min-width: 768px) {
      section.team {
        background-image:
          url("@/themes/light/images/landing/team-back.png"),
          url("@/themes/light/images/landing/team-back-left.png"),
          url("@/themes/light/images/landing/team-back-right.png")
        ;
        background-size: auto, 50% 50%, 50% 50%;
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: left bottom, left top, right top;

        padding: 120px 115px 70px 115px;
      }
    }

    @media (min-width: 992px) {
      section.team {
        background-image:
          url("@/themes/light/images/landing/team-back.png"),
          url("@/themes/light/images/landing/team-back-left.png"),
          url("@/themes/light/images/landing/team-back-right.png")
        ;
        background-size: auto, 50% 50%, 50% 50%;
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: left bottom, left top, right top;
      }
    }

    @media (min-width: 1200px) {
      section.team {
        background-image:
          url("@/themes/light/images/landing/team-back.png"),
          url("@/themes/light/images/landing/team-back-left.png"),
          url("@/themes/light/images/landing/team-back-right.png")
        ;
        background-size: auto, auto, auto;
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: left bottom, left top, right top;
      }
    }
  }
