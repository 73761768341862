.progress-bar { 
  height: 16px;
  position: relative;
  border-radius: 6px;

  .value {
    position: absolute;
    right: 8px;
    top: 0;
    font-size: 14px;
    line-height: 16px;
    z-index: 1;
    color: $color-font-soft
  }

  .users {
    position: absolute;
    display: flex;
    top: -3px;
    left: 16px;
    z-index: 1;

    .item {
      margin-left: -6px;
      width: 20px;
      height: 20px;
      border-radius: 8px;
      color: #FFFFFF;
      text-align: center;
      line-height: 20px;
      border: 1px solid #FFFFFF;
    }
  }

  span {
    position: relative;
    display: block;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }

  &.simple {
    height: 18px;
    border-radius: 4px;

    span {
      border-radius: 4px;
    }
  }
}