div.field,
div.widget {
  .description {
    color: $color-font-light;
    margin-top: 4px;
  }

  .placeholder {
    color: $color-font-light;
  }

  .confirm {
    width: 16px;
    height: 16px;
    top: 8px;
    right: 7px;
    border-radius: 20px;
    background: $color-base-denial;
  
    &.active {
      background: $color-base-selection;
    }
  }

  div.hover-actions {
    &:hover {
      .icon-edit,
      .icon-remove {
        display: block !important;
      }
    }
  }
}