div.autocomplete {
  position: relative;

  div.dropdown {
    position: absolute;
    width: calc(100% - 2px);
    padding: 0px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: $font-text;
    font-style: normal;
    font-weight: normal;
    color: $color-font-dark;
    z-index: 10;

    div.item {
      padding: 10px;
      border-bottom: 1px solid $color-border-light;
      cursor: pointer;
      &:hover,
      &.active {
        background: $color-base-light;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}