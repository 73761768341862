
  .landing {
    section.overview {
      .container-fluid {
        display: flex;
        min-height: 580px;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 70px;
      }

      .service {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 270px;
        height: 170px;
        margin: 15px;
        box-shadow: 0 0 10px rgba(0, 10, 20, 0.1);

        div {
          position:absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% - 30px);
          height: calc(100% - 30px);
          -webkit-transition: opacity 0.5s ease-in-out;
          -moz-transition: opacity 0.5s ease-in-out;
          -o-transition: opacity 0.5s ease-in-out;
          transition: opacity 0.5s ease-in-out;
          padding: 15px;
          border-radius: 10px;
          cursor: default;
        }

        .caption {
          background: #FFFFFF;
          font-size: 150%;
          border-radius: 9px;
        }

        .details {
          background: #4E89D7;
          color: #FFF;
          font-size: 87.5%;
        }

        .caption:hover {
          opacity:0;
        }
      }
    }

    @media (min-width: 576px) {
      section.overview {
        .container-fluid {
          flex-direction: row;
        }

        .service {
          height: 280px;
          min-width: 180px;
          max-width: 275px;

          .details {
            font-size: 112.5%;
          }
        }
      }
    }

    @media (min-width: 768px) {
      section.overview {
        min-height: 570px;
        background: url("@/themes/light/images/landing/services-back-services.png") no-repeat left 70px;
      }
    }

    @media (min-width: 992px) {
      section.overview {
        .service {
          width: 270px;
        }
      }
    }
  }
