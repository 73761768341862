
  .landing {
    section.services {
      z-index: -1;
      background-repeat: no-repeat;
      background-position: right top;

      .container-fluid {
        height: 580px;
        margin-top: 100px;
        position: relative;
      }

      .content {
        display: none;
        max-width: 55%;
        margin-top: 20px;
        padding-left: 20px;
      }

      .service {
        position: absolute;
        margin: 0 30px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: left center;

        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;

        .caption {
          color: #4E89D7;
          font-size: 150%;
          margin: 20px 0;
        }

        .divider {
          width: 22%;
          height: 1px;
          margin: 20px 0;
          transform: scaleX(4.0);
          transform-origin: left top;
          border-top: 1px dashed #1F67C8;
        }

        .process {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;

          .step {
            margin-bottom: 20px;
            background-repeat: no-repeat;
            background-position: left top;
          }

          .step:not(:nth-child(1)) {
            background-image: url("@/themes/light/images/landing/services-mark.png");
          }
        }
      }

      .pointer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 10px;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .preload {
        display: none;
      }
    }

    section.services[data-service="0"] {
      .pointer {
        background-image: url("@/themes/light/images/landing/services-pointer-step-1-line.png");
      }
      .service:nth-child(3) { opacity: 1; }
      .service:nth-child(4) { opacity: 0; }
      .service:nth-child(5) { opacity: 0; }
      .service:nth-child(6) { opacity: 0; }
    }

    section.services[data-service="1"] {
      .pointer {
        background-image: url("@/themes/light/images/landing/services-pointer-step-2-line.png");
      }
      .service:nth-child(3) { opacity: 0; }
      .service:nth-child(4) { opacity: 1; }
      .service:nth-child(5) { opacity: 0; }
      .service:nth-child(6) { opacity: 0; }
    }

    section.services[data-service="2"] {
      .pointer {
        background-image: url("@/themes/light/images/landing/services-pointer-step-3-line.png");
      }
      .service:nth-child(3) { opacity: 0; }
      .service:nth-child(4) { opacity: 0; }
      .service:nth-child(5) { opacity: 1; }
      .service:nth-child(6) { opacity: 0; }
    }

    section.services[data-service="3"] {
      .pointer {
        background-image: url("@/themes/light/images/landing/services-pointer-step-4-line.png");
      }
      .service:nth-child(3) { opacity: 0; }
      .service:nth-child(4) { opacity: 0; }
      .service:nth-child(5) { opacity: 0; }
      .service:nth-child(6) { opacity: 1; }
    }

    @media (max-width: 576px) {
      section.services {
        background-position: right bottom;

        .container-fluid {
          height: 700px;
          margin-top: 0px;
          padding-top: 100px;
        }

        .service {
          .step:not(:nth-child(1)) {
            padding-top: 29px;
          }
        }

        .pointer {
          bottom: 200px;
        }

        .preload {
          background: url("@/themes/light/images/landing/services-architecture-xs.png"),
                      url("@/themes/light/images/landing/services-management-xs.png"),
                      url("@/themes/light/images/landing/services-innovations-xs.png");
        }
      }

      section.services[data-service="0"] {
        background-image: url("@/themes/light/images/landing/services-analytics-xs.png");
      }

      section.services[data-service="1"] {
        background-image: url("@/themes/light/images/landing/services-architecture-xs.png");
      }

      section.services[data-service="2"] {
        background-image: url("@/themes/light/images/landing/services-management-xs.png");
      }

      section.services[data-service="3"] {
        background-image: url("@/themes/light/images/landing/services-innovations-xs.png");
      }
    }

    @media (max-width: 576px) {
      section.services {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    @media (min-width: 576px) {
      section.services {
        padding-left: 10px;
        padding-right: 10px;

        .container-fluid {
          height: 894px;
          margin-top: -150px;
          padding-top: 150px;
        }

        .content {
          display: block;
        }

        .service {
          width: 55%;
          font-size: 112.5%;

          .step {
            padding-top: 29px;
          }
        }

        .pointer {
          bottom: 250px;
        }

        .preload {
          background: url("@/themes/light/images/landing/services-architecture-sm.png"),
                      url("@/themes/light/images/landing/services-management-sm.png"),
                      url("@/themes/light/images/landing/services-innovations-sm.png");
        }
      }

      section.services[data-service="0"] {
        background-image: url("@/themes/light/images/landing/services-analytics-sm.png");
      }

      section.services[data-service="1"] {
        background-image: url("@/themes/light/images/landing/services-architecture-sm.png");
      }

      section.services[data-service="2"] {
        background-image: url("@/themes/light/images/landing/services-management-sm.png");
      }

      section.services[data-service="3"] {
        background-image: url("@/themes/light/images/landing/services-innovations-sm.png");
      }
    }

    @media (min-width: 768px) {
      section.services {
        .service {
          .divider {
            width: 28%;
          }

          .process {
            flex-direction: row;

            .step {
              padding: 0 18px 0 27px;
              background-position: 0 9px;
            }

            .step:nth-child(1) {
              padding-left: 0;
            }
          }
        }

        .pointer {
          bottom: 470px;
        }
      }
    }

    @media (min-width: 992px) {
      section.services {
        margin-top: -360px;
        padding-top: 650px;

        .container-fluid {
          padding-left: 50px;
          padding-right: 50px;
        }

        .service {
          width: 55%;
          min-height: 300px;
          margin-top: 50px;
          margin-left: -30px;
          padding-left: 50px;

          .divider {
            width: 25%;
          }

          .process {
            flex-direction: row;

            .step {
              padding-top: 0;
            }

            .step:nth-child(1) {
              padding-left: 0;
            }
          }
        }

        .pointer {
          display: none;
        }

        .preload {
          background: url("@/themes/light/images/landing/services-architecture-lg.png"),
                      url("@/themes/light/images/landing/services-management-lg.png"),
                      url("@/themes/light/images/landing/services-innovations-lg.png");
        }
      }

      section.services[data-service="0"] {
        background-image: url("@/themes/light/images/landing/services-analytics-lg.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-1.png");
        }
      }

      section.services[data-service="1"] {
        background-image: url("@/themes/light/images/landing/services-architecture-lg.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-2.png");
        }
      }

      section.services[data-service="2"] {
        background-image: url("@/themes/light/images/landing/services-management-lg.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-3.png");
        }
      }

      section.services[data-service="3"] {
        background-image: url("@/themes/light/images/landing/services-innovations-lg.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-4.png");
        }
      }
    }

    @media (min-width: 1200px) {
      section.services {
        height: 1750px;
        margin-top: -560px;
        padding-top: 810px;

        .service {
          width: 55%;
          min-height: 250px;
        }

        .preload {
          background: url("@/themes/light/images/landing/services-architecture-xl.png"),
                      url("@/themes/light/images/landing/services-management-xl.png"),
                      url("@/themes/light/images/landing/services-innovations-xl.png");
        }
      }

      section.services[data-service="0"] {
        background-image: url("@/themes/light/images/landing/services-analytics-xl.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-1.png");
        }
      }

      section.services[data-service="1"] {
        background-image: url("@/themes/light/images/landing/services-architecture-xl.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-2.png");
        }
      }

      section.services[data-service="2"] {
        background-image: url("@/themes/light/images/landing/services-management-xl.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-3.png");
        }
      }

      section.services[data-service="3"] {
        background-image: url("@/themes/light/images/landing/services-innovations-xl.png");

        .service {
          background-image: url("@/themes/light/images/landing/services-pointer-step-4.png");
        }
      }
    }
  }
