.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
}
  
.switch input { display:none; }

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-base-light;
  -webkit-transition: .4s;
  transition: .4s;
  cursor: pointer;
}

.slider:before {
  position: absolute;
  opacity: 1;
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: $color-base-highlight;
  -webkit-transition: .4s;
  transition: .4s;
  content: "";
}

input:checked + .slider {
  background-color: rgba(12, 174, 255, 0.32);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-base-highlight;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}