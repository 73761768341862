
  .content {
    display: flex;
    align-items: center;
    position: relative;

    & .icons {
      background-repeat: no-repeat;
      width: 0;
      height: 17px;
    }

    & .id {
      display: flex;
      border: 2px solid transparent;
      border-radius: 4px;
    }

    & .text {
      display: flex;
      border: 2px solid transparent;
      border-radius: 4px;
      padding-right: 8px;
      padding-left: 8px;
    }
  }
