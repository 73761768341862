div.box-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  &.col-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  &.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  &.col-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}

div.box-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}