ul.tabs {
  display: flex;
  padding-inline-start: 0px;

  & > li.tab {
    width: 200px;
    list-style: none;
    position: relative;

    div.item {
      padding: 10px;
      text-align: center;
      font-size: $font-text;
      color: $color-font-dark;
      border-bottom: 1px solid transparent;

      & > .dropdown {
        width: calc(100% - 2px);
        position: absolute;
        top: 32px;
        background: $color-base-neutral;
        border: 1px solid transparent;
        z-index: 50;

        .tab:hover div.item {
          border-bottom: 1px solid $color-border-highlight;
          color: $color-font-highlight;
        }
      }
    }

    &.active > div.item {
      border-bottom: 1px solid $color-border-highlight;
      color: $color-font-highlight;
      cursor: default;
    }

    &:hover > div.item {
      border-bottom: 1px solid $color-border-highlight;
      color: $color-font-highlight;
    }
  }
}