
  .landing {
    section.intro {
      background-image:
        url("@/themes/light/images/landing/intro-back-xs-top.png"),
        url("@/themes/light/images/landing/intro-back-xs-bottom.png")
      ;
      background-repeat: no-repeat;
      background-position: right top, left bottom;

      .container-fluid {
        min-height: 580px;
        padding-top: 100px;
      }

      .item {
        font-size: 225%;
        margin-top: 5px;
        margin-left: 15%;
      }

      .analytics    { color: #68788D; }
      .architecture { color: #4775B1; }
      .management   { color: #1F67C8; }
      .development  { color: #164D94; }

      button.details {
        margin: auto;
        margin-top: 140px;
        margin-left: 15%;
      }
    }

    @media (min-width: 400px) {
      section.intro {
        .analytics    { margin-left: 25%; }
        .architecture { margin-left: 25%; }
        .management   { margin-left: 25%; }
        .development  { margin-left: 25%; }

        button.details { margin-left: 25%; }
      }
    }

    @media (min-width: 600px) {
      section.intro {
        background: none;

        .container-fluid {
          min-height: 340px;
          padding-top: 0;
          padding-left: 15%;
          padding-right: 15%;
          background-repeat:  no-repeat;
          background-position: right top;
          background-image: url("@/themes/light/images/landing/intro-back-sm.png");
        }

        .analytics    { margin-left: 0; }
        .architecture { margin-left: 0; }
        .management   { margin-left: 0; }
        .development  { margin-left: 0; }

        button.details {
          margin-top: 100px;
          margin-left: 0;
        }
      }
    }

    @media (min-width: 768px) {
      section.intro {
        background: none;

        .container-fluid {
          min-height: 460px;
          padding-top: 100px;
          background-repeat:  no-repeat;
          background-position: right center;
          background-image: url("@/themes/light/images/landing/intro-back-sm.png");
        }
      }
    }

    @media (min-width: 992px) {
      section.intro {
        background: none;

        .container-fluid {
          min-height: 560px;
          padding-top: 100px;
          padding-left: 10%;
          padding-right: 10%;
          background-repeat:  no-repeat;
          background-position: right top;
          background-image: url("@/themes/light/images/landing/intro-back-lg.png");
        }

        button.details { margin-top: 80px; }
      }
    }


    @media (min-width: 1200px) {
      section.intro {
        .container-fluid {
          padding-left: 15%;
          padding-right: 15%;
        }
      }
    }

    @media (min-width: 1400px) {
      section.intro {
        .container-fluid {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    }
  }
