
  .node {
    border: 1px solid transparent;
    cursor: pointer;

    & > .content {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
