.widgets-crumbs {
  display: flex;
  flex-wrap: wrap;

  .member {
    display: flex;
    color: $color-font-soft;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;

    &::after{
      display: inline-block;
      padding-right: .5rem;
      padding-left: .5rem;
      color: $color-font-soft;
      content: "/";
    }

    &:hover {
      color: $color-font-highlight;

      &::after{
        color: $color-font-soft;
      }
    }
  }
}
