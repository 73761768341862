.vue__time-picker {
  display:inline-block;
  position:relative;
  font-size:1em;
  width:100%;
  font-family:sans-serif;
  vertical-align:middle;
  margin: 5px 0px;
}
.vue__time-picker *{
  box-sizing:border-box;
}
.vue__time-picker input.vue__time-picker-input {
  border:1px solid #d2d2d2;
  width:100%;
  height:50px;
  padding:.3em .5em;
  font-size:1em;
}
.vue__time-picker input.has-custom-icon {
  padding-left:1.8em;
}
.vue__time-picker input.vue__time-picker-input.invalid:not(.skip-error-style) {
  border-color:#c03;
  outline-color:#c03;
}
.vue__time-picker input.vue__time-picker-input.disabled,
.vue__time-picker input.vue__time-picker-input:disabled {
  color:#d2d2d2;
}
.vue__time-picker .controls {
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  z-index:3;
  display:flex;
  flex-flow:row nowrap;
  justify-content:flex-end;
  align-items:stretch;
  pointer-events:none;
}
.vue__time-picker .controls>* {
  cursor:pointer;
  width:auto;
  display:flex;
  flex-flow:column nowrap;
  justify-content:center;
  align-items:center;
  padding:0 .35em;
  color:#d2d2d2;
  line-height:100%;
  font-style:normal;
  pointer-events:auto;
  transition:color .2s,opacity .2s;
}
.vue__time-picker .controls>:hover {
  color:#797979;
}
.vue__time-picker .controls>:active,
.vue__time-picker .controls>:focus {
  outline:0;
}
.vue__time-picker .controls .char {
  font-size:1.1em;
  line-height:100%;
  -webkit-margin-before:-.15em;
}
.vue__time-picker .custom-icon {
  z-index:2;
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  width:1.8em;
  display:flex;
  flex-flow:column nowrap;
  justify-content:center;
  align-items:center;
  pointer-events:none;
}
.vue__time-picker .controls img,
.vue__time-picker .controls svg,
.vue__time-picker .custom-icon img,
.vue__time-picker .custom-icon svg {
  display:inline-block;
  vertical-align:middle;
  margin:0;
  border:0;
  outline:0;
  max-width:1em;
  height:auto;
}
.vue__time-picker .time-picker-overlay {
  z-index:4;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.vue__time-picker .dropdown {
  position:absolute;
  z-index:5;
  top:calc(50px + 2px);
  left:0;
  background:#fff;
  box-shadow:0 1px 6px rgba(0,0,0,.15);
  width:100%;
  height:10em;
  font-weight:400;
}
.vue__time-picker .dropdown.drop-up {
  top:auto;
  bottom:calc(50px + 1px);
}
.vue__time-picker .dropdown .select-list {
  width:100%;
  height:10em;
  overflow:hidden;
  display:flex;
  flex-flow:row nowrap;
  align-items:stretch;
  justify-content:space-between;
}
.vue__time-picker .dropdown .select-list:active,
.vue__time-picker .dropdown .select-list:focus {
  outline:0;
}
.vue__time-picker .dropdown ul {
  padding:0;
  margin:0;
  list-style:none;
  outline:0;
  flex:1 1 0.00001px;
  overflow-x:hidden;
  overflow-y:auto;
}
.vue__time-picker .dropdown ul.apms,
.vue__time-picker .dropdown ul.minutes,
.vue__time-picker .dropdown ul.seconds {
  border-left:1px solid #fff;
}
.vue__time-picker .dropdown ul li {
  list-style:none;
  text-align:center;
  padding:.3em 0;
  color:#161616;
}
.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):focus,
.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):hover {
  background:rgba(0,0,0,.08);
  color:#161616;
  cursor:pointer;
}.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: $color-neutral;
  color: $color-font-neutral;
}
.vue__time-picker .dropdown ul li[disabled],
.vue__time-picker .dropdown ul li[disabled]:hover {
  background:transparent;
  opacity:.3;
  cursor:not-allowed;
}
.vue__time-picker .dropdown .hint {
  display: none;
  color:#a5a5a5;
  cursor:default;
  font-size:.8em;
}

.plus {
  --t:2px;   /* Thickness */
  --l:40px;  /* size of the symbol */
  --s:10px;  /* space around the symbol */
  --c1:#fff; /* Plus color*/
  --c2:#000; /* background color*/

  display:inline-block;
  width:var(--l);
  height:var(--l);
  padding:var(--s);
  box-sizing:border-box; /*Remove this if you don't want space to be included in the size*/
  
  background:
    linear-gradient(var(--c1),var(--c1)) content-box,
    linear-gradient(var(--c1),var(--c1)) content-box,
    var(--c2);
  background-position:center;
  background-size: 100% var(--t),var(--t) 100%;
  background-repeat:no-repeat;
}

.minus {
  --t:2px;   /* Thickness */
  --l:40px;  /* size of the symbol */
  --s:10px;  /* space around the symbol */
  --c1:#fff; /* Plus color*/
  --c2:#000; /* background color*/

  display:inline-block;
  width:var(--l);
  height:var(--l);
  padding:var(--s);
  box-sizing:border-box; /*Remove this if you don't want space to be included in the size*/
  
  background:
    linear-gradient(var(--c1),var(--c1)) content-box,
    var(--c2);
  background-position:center;
  background-size: 100% var(--t),var(--t) 100%;
  background-repeat:no-repeat;
}

.radius {
  border: 1px solid black; 
  border-radius: 50%; 
}