.vc-container {
  height: 272px !important;
  overflow-y: scroll;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
  &::-webkit-scrollbar { width: 0; height: 0;}

  .vc-weekday {
    color: $color-font-light;
  }

  .vc-day {
    min-height: 32px;
    border: 1px solid $color-border-light;
  }

  .vc-day.is-not-in-month * {
    opacity: 0.5;
  }
}

.vc-container.full {
  height: 99% !important;
  width: 100% !important;
  overflow-y: scroll;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
  &::-webkit-scrollbar { width: 0; height: 0;}

  .vc-pane-layout {
    height: 100%;
  }

  .vc-weeks {
    height: 100%;
    grid-template-rows: 32px auto;
  }

  .vc-weekday {
    height: 32px;
  }

  .vc-day {
    min-height: 100%;
    border: 1px solid $color-border-light;
  }
}

@media (max-width: 1350px) {
  .vc-container.full {
    .vc-day {
      min-height: 32px;
    }
  }

  .vc-weeks {
    display: flex !important;
    flex-direction: column !important;
  }

  // .vc-day.is-not-in-month {
  //   display: none;
  // }

  // .vc-weekday {
  //   display: none;
  // }
}