.hint-wrapper {
  position: relative; 

  .hint {
    visibility: hidden;
    width: 120px;
    background-color: $color-base-dark;
    color: $color-font-neutral;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 111;

    opacity: 0;
    transition: opacity 1s;

    &.top {
      width: 100px;
      bottom: 100%;
      margin-left: -50px;

      &::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }

      &.left { left: 20%; }
      &.center { left: 50%; }
      &.right { left: 80%; }
    }

    &.bottom {
      width: 100px;
      top: 100%;
      margin-left: -50px;

      &::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
      }

      &.left { left: 20%; }
      &.center { left: 50%; }
      &.right { left: 80%; }
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
}