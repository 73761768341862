.tracker {
  z-index: 100;

  .title {
    line-height: 24px;
    font-size: 18px;
    font-weight: normal;
    color: $color-font-dark;
  }

  .path {
    font-size: 10px;
    font-weight: normal;
    color: $color-font-soft;
  }

  .content {
    .launch {
      width: 32px;
      height: 32px;
      border-radius: 18px;
      border: 2px solid $color-base-dark;
      background-size: auto;
      border-color: $color-border-highlight;
    }

    .time {
      margin-left: 10px;
      margin-right: 10px;

      .title {
        text-align: right;
        color: $color-font-highlight;
      }
    }

    .node {
      flex: 1;
      min-width: 0;
      margin-left: 10px;
      margin-right: 10px;

      .title, .path {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        line-height: 23px;
        max-height: 36px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    &.pause {
      .launch {
        border-color: $color-border-denial;
      }

      .time > .title {
        color: $color-font-denial;
      }
    }

    .toogle {
      width: 36px;
      height: 36px;

      &.menu-list:hover {
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(165.6deg) brightness(0.59);
      }

      &.icon-exclamation:hover {
        filter: invert(0.4) sepia(1) saturate(20) hue-rotate(165.6deg) brightness(0.59);
      }
    }
  }

  .details {
    top: 50px;
    left: -8px;
    right: -8px;
    background-color: $color-base-neutral;
    transform: rotateX(-90deg);
    transform-origin: top center;
    transition: transform .01s ease;

    &:focus {
      outline: none;
    }

    &.visible {
      transform: rotateX(0deg);
    }

    .filter {
      margin-bottom: 10px;
      padding: 20px 10px 0 10px;
    }

    .list {
      padding: 10px;

      .node {
        padding: 5px 10px;
        margin-top: 10px;

        &:hover {
          background-color: $color-base-highlight;
          border-radius: $border-radius-primary;

          .title, .path {
            color: $color-font-neutral;
          }
        }

        &.active {
          background-color: $color-font-dark;
        }

        .name {
          min-width: 36px;

          .title, .path {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

        }

        .time {
          margin-left: 10px;
        }
      }
    }
  }

  &.hinted {
    .tooltip-text {
      opacity: 1;
      visibility: visible;
    }
  }
}