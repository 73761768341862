
  div.charts-canvas {
    .target {
      width: 68%;
      height: 30px;
      border: 1px dashed grey;
      position: absolute;
      display: none;
    }

    .target-above {
      top:0;
      left: 0;
    }

    .target-under {
      left: 0;
      bottom: 0;
    }

    .target-child {
      top: 0;
      right: 0;
      width: 30%;
      height: 100%;
    }

    .target-over {
      border: 3px dashed grey;
    }

    .node {
      .target {
        display: block;
      }
    }

    .complex {
      .target-child {
        width: 0%;
        border: 0;
      }

      .target-above {
        width: 100%;
      }

      .target-under {
        width: 100%;
      }
    }
  }
