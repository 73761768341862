div.checkbox {
  display: flex;

  &:hover {
    input ~ .checkmark {
      background-color: $color-base-neutral;
    }
  }

  label {
    display: flex;
    position: relative;
    width: 100%;
    padding-left: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: $font-text;
    color: $color-font-dark;
    cursor: pointer;

    input.checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkmark {
        opacity: 1;
        border: 1px solid $color-border-highlight;
        background-color: $color-base-highlight;
      }

      &:checked ~ .checkmark:after {
        display: block;
      }

      &:checked ~ .field {
        color: $color-font-highlight;
        &.through {
          text-decoration: line-through;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      opacity: 0.54;
      border: 1px solid $color-border-dark;
      border-radius: 7px;
      background-color: $color-base-neutral;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 3px;
        left: 6px;
        width: 4px;
        height: 7px;
        border: solid $color-border-neutral;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .text {
      max-width: calc(100% - 35px);
    }
  }

  &.disabled {
    label {
      color: $color-font-light;

      input.checkbox {
        cursor: default;
  
        &:checked ~ .checkmark {
          border: 1px solid $color-border-light;
          background-color: $color-base-light;
        }
      }

      .checkmark {
        border: 1px solid $color-border-light;
      }

      .text {
        color: $color-font-light;
      }
    }
  }
}