.roadmap {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  border: 1px solid;
  border-color: $color-border-soft;
  border-radius: $border-radius-primary;
  font-size: $font-text-secondary;
  color: $color-font-dark;
  text-decoration: none;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: $font-text;
    border-radius: $border-radius-primary $border-radius-primary 0 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 0 0 $border-radius-primary $border-radius-primary;
  }

  .shipped {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid;
    border-color: $color-border-neutral;
    background-size: auto;
    background-color: $color-base-neutral;
    cursor: pointer;

    &:hover {
      border-color: $color-border-soft;
    }
  }

  &:focus {
    outline:none;
    border-color: $color-border-highlight;
  }

  &.sprint {
    &:focus {
      outline:none;
      border-color: $color-border-soft;
    }

    .header {
      background-color: $color-base-soft;
    }
  }

  &.selected {
    border-color: $color-border-highlight-slave;

    &.sprint {
      border-color: $color-border-highlight;
    }
  }
}