$color-base-neutral               : #FFFFFF;
$color-base-light                 : #EEEEEE;
$color-base-soft                  : #FAFAFA;
$color-base-soft-shadow           : #F5F5F5;
$color-base-highlight             : #0CAEFF;
$color-base-highlight-shadow      : #edf7fc;
$color-base-highlight-slave       : #8180CE;
$color-base-highlight-slave-shadow: #f2f2ff;
$color-base-denial                : #DA1414;
$color-base-selection             : #70BD63;
$color-base-dark                  : #232D31;

$color-border-neutral               : #FFFFFF;
$color-border-light                 : #E5E5E5;
$color-border-soft                  : #DEDEDE;
$color-border-highlight             : #0CAEFF;
$color-border-highlight-slave       : #8180CE;
$color-border-highlight-slave-shadow: #CFCFF2;
$color-border-option                : #6C6D70;
$color-border-denial                : #DA1414;
$color-border-selection             : #70BD63;
$color-border-dark                  : #353B3D;

$color-font-neutral  : #FFFFFF;
$color-font-light    : #757575;
$color-font-soft     : #989898;
$color-font-highlight: #0CAEFF;
$color-font-title    : #0D3061;
$color-font-denial   : #DA1414;
$color-font-selection: #70BD63;
$color-font-dark     : #232D31;

$font-family: Roboto;

$font-text                 : 12px;
$font-text-secondary       : 10px;
$font-text-header          : 200%;
$font-text-header-secondary: 16px;
$font-text-avatar          : 400%;

$border-radius-primary  : 8px;
$border-radius-secondary: 4px;
$border-radius-header   : 12px;

$box-shadow: #D9D9D9;


$color-success       : #27AE60;
$color-success-soft  : #4DC780;
$color-neutral       : #0288D1;
$color-neutral-soft  : #3FB1EE;
$color-denial-soft   : #FFCDCD;