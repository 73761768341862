.milestone {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: $color-border-soft;
  background-color: $color-base-soft;
  font-size: $font-text-secondary;
  color: $color-font-dark;
  text-decoration: none;
  border-radius: $border-radius-primary;

  .header {
    display: flex;
    justify-content: space-between;
    background-color: $color-base-soft;
    font-size: $font-text;
    border-radius: $border-radius-primary;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $color-base-soft;
    border-radius: $border-radius-primary;
  }

  .shipped {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid;
    border-color: $color-border-neutral;
    background-size: auto;
    background-color: $color-base-neutral;
    cursor: pointer;

    &:hover {
      border-color: $color-border-soft;
    }
  }

  &.simple {
    flex-direction: row;
    border: 1px solid;
    border-color: $color-border-soft;
    border-radius: $border-radius-primary;
    background-color: $color-base-neutral;

    .header {
      border-radius: $border-radius-primary $border-radius-primary 0 0 ;
      background-color: $color-base-neutral;
    }

    .content {
      border-radius: $border-radius-primary;
      background-color: $color-base-neutral;
    }
  }

  &.selected {
    &.main {
      border: 1px solid;
      border-color: $color-border-neutral;
      border-radius: $border-radius-primary;
      background-color: $color-base-neutral;

      .header {
        background-color: $color-base-neutral;
      }

      .content {
        background-color: $color-base-neutral;
      }
    }
  }
}