select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid $color-border-light;
  border-radius: $border-radius-primary;
  font-size: $font-text;
  background: $color-base-neutral;
  color: $color-font-dark;

  &:focus,
  &:focus-visible {
    border-color: $color-border-highlight;
    outline: none;
  }

  &::placeholder {
    color: $color-font-light;
  }

  &.clear {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}