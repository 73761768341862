.icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.expand-x { width: 100%; }

  &.little {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
  }

  &.small {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
  }

  &.little {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  &.medium {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  &.medium {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  &.large {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }
}

.logo-long {
  width: 128px;
  height: 32px;
  background-image: url("@/themes/light/images/logo/long.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.logo-long-hover {
  width: 128px;
  height: 32px;
  background-image: url("@/themes/light/images/logo/long-hover.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.logo-short {
  width: 36px;
  height: 36px;
  background-image: url("@/themes/light/images/logo/short.svg");
}

.icon-toolbar-schedule { background-image: url("@/themes/light/images/toolbar/schedule.svg"); }
.icon-toolbar-question { background-image: url("@/themes/light/images/toolbar/question.svg"); }
.icon-toolbar-roadmap  { background-image: url("@/themes/light/images/toolbar/roadmap.svg");  }
.icon-toolbar-planner  { background-image: url("@/themes/light/images/toolbar/planner.svg");  }
.icon-toolbar-schema   { background-image: url("@/themes/light/images/toolbar/schema.svg");   }
.icon-toolbar-setting  { background-image: url("@/themes/light/images/toolbar/setting.svg");  }
.icon-toolbar-stream   { background-image: url("@/themes/light/images/toolbar/stream.svg");   }
.icon-toolbar-tasks    { background-image: url("@/themes/light/images/toolbar/tasks.svg");    }
.icon-toolbar-people   { background-image: url("@/themes/light/images/toolbar/people.svg");   }
.icon-toolbar-projects { background-image: url("@/themes/light/images/toolbar/projects.svg"); }
.icon-toolbar-expenses { background-image: url("@/themes/light/images/toolbar/expenses.svg"); }

.icon-menu-list     { background-image: url("@/themes/light/images/menu/list.svg");     }
.icon-menu-context  { background-image: url("@/themes/light/images/menu/context.svg");  }
.icon-menu-dropdown { background-image: url("@/themes/light/images/menu/dropdown.svg"); }
.icon-menu-main     { background-image: url("@/themes/light/images/menu/main.svg");     }

.icon-progress-calendar-denial     { background-image: url("@/themes/light/images/progress/calendar-denial.png");     }
.icon-progress-calendar-empty      { background-image: url("@/themes/light/images/progress/calendar-empty.png");      }
.icon-progress-calendar-full       { background-image: url("@/themes/light/images/progress/calendar-full.png");       }
.icon-progress-calendar-progress   { background-image: url("@/themes/light/images/progress/calendar-progress.png");   }
.icon-progress-completed-denial    { background-image: url("@/themes/light/images/progress/completed-denial.png");    }
.icon-progress-completed-empty     { background-image: url("@/themes/light/images/progress/completed-empty.png");     }
.icon-progress-completed-full      { background-image: url("@/themes/light/images/progress/completed-full.png");      }
.icon-progress-completed-progress  { background-image: url("@/themes/light/images/progress/completed-progress.png");  }
.icon-progress-complexity-denial   { background-image: url("@/themes/light/images/progress/complexity-denial.png");   }
.icon-progress-complexity-empty    { background-image: url("@/themes/light/images/progress/complexity-empty.png");    }
.icon-progress-complexity-full     { background-image: url("@/themes/light/images/progress/complexity-full.png");     }
.icon-progress-complexity-progress { background-image: url("@/themes/light/images/progress/complexity-progress.png"); }
.icon-progress-estimate-denial     { background-image: url("@/themes/light/images/progress/estimate-denial.png");     }
.icon-progress-estimate-empty      { background-image: url("@/themes/light/images/progress/estimate-empty.png");      }
.icon-progress-estimate-full       { background-image: url("@/themes/light/images/progress/estimate-full.png");       }
.icon-progress-estimate-progress   { background-image: url("@/themes/light/images/progress/estimate-progress.png");   }

.progress-percent-0   { background-image: url("@/themes/light/images/progress/percents-0.png");   }
.progress-percent-5   { background-image: url("@/themes/light/images/progress/percents-5.png");   }
.progress-percent-10  { background-image: url("@/themes/light/images/progress/percents-10.png");  }
.progress-percent-15  { background-image: url("@/themes/light/images/progress/percents-15.png");  }
.progress-percent-20  { background-image: url("@/themes/light/images/progress/percents-20.png");  }
.progress-percent-25  { background-image: url("@/themes/light/images/progress/percents-25.png");  }
.progress-percent-30  { background-image: url("@/themes/light/images/progress/percents-30.png");  }
.progress-percent-35  { background-image: url("@/themes/light/images/progress/percents-35.png");  }
.progress-percent-40  { background-image: url("@/themes/light/images/progress/percents-40.png");  }
.progress-percent-45  { background-image: url("@/themes/light/images/progress/percents-45.png");  }
.progress-percent-50  { background-image: url("@/themes/light/images/progress/percents-50.png");  }
.progress-percent-55  { background-image: url("@/themes/light/images/progress/percents-55.png");  }
.progress-percent-60  { background-image: url("@/themes/light/images/progress/percents-60.png");  }
.progress-percent-65  { background-image: url("@/themes/light/images/progress/percents-65.png");  }
.progress-percent-70  { background-image: url("@/themes/light/images/progress/percents-70.png");  }
.progress-percent-75  { background-image: url("@/themes/light/images/progress/percents-75.png");  }
.progress-percent-80  { background-image: url("@/themes/light/images/progress/percents-80.png");  }
.progress-percent-85  { background-image: url("@/themes/light/images/progress/percents-85.png");  }
.progress-percent-90  { background-image: url("@/themes/light/images/progress/percents-90.png");  }
.progress-percent-95  { background-image: url("@/themes/light/images/progress/percents-95.png");  }
.progress-percent-100 { background-image: url("@/themes/light/images/progress/percents-100.png"); }
.progress-percent-105 { background-image: url("@/themes/light/images/progress/percents-105.png"); }
.progress-percent-110 { background-image: url("@/themes/light/images/progress/percents-110.png"); }
.progress-percent-115 { background-image: url("@/themes/light/images/progress/percents-115.png"); }
.progress-percent-120 { background-image: url("@/themes/light/images/progress/percents-120.png"); }
.progress-percent-125 { background-image: url("@/themes/light/images/progress/percents-125.png"); }
.progress-percent-130 { background-image: url("@/themes/light/images/progress/percents-130.png"); }
.progress-percent-135 { background-image: url("@/themes/light/images/progress/percents-135.png"); }
.progress-percent-140 { background-image: url("@/themes/light/images/progress/percents-140.png"); }
.progress-percent-145 { background-image: url("@/themes/light/images/progress/percents-145.png"); }
.progress-percent-150 { background-image: url("@/themes/light/images/progress/percents-150.png"); }
.progress-percent-155 { background-image: url("@/themes/light/images/progress/percents-155.png"); }
.progress-percent-160 { background-image: url("@/themes/light/images/progress/percents-160.png"); }
.progress-percent-165 { background-image: url("@/themes/light/images/progress/percents-165.png"); }
.progress-percent-170 { background-image: url("@/themes/light/images/progress/percents-170.png"); }
.progress-percent-175 { background-image: url("@/themes/light/images/progress/percents-175.png"); }
.progress-percent-180 { background-image: url("@/themes/light/images/progress/percents-180.png"); }
.progress-percent-185 { background-image: url("@/themes/light/images/progress/percents-185.png"); }
.progress-percent-190 { background-image: url("@/themes/light/images/progress/percents-190.png"); }
.progress-percent-195 { background-image: url("@/themes/light/images/progress/percents-195.png"); }
.progress-percent-200 { background-image: url("@/themes/light/images/progress/percents-200.png"); }

.icon-arrow-top       { background-image: url("@/themes/light/images/arrow/top.svg");       }
.icon-arrow-top-hover { background-image: url("@/themes/light/images/arrow/top-hover.svg"); }
.icon-arrow-bottom    { background-image: url("@/themes/light/images/arrow/bottom.svg");    }
.icon-arrow-back-1    { background-image: url("@/themes/light/images/arrow/back.svg");      }
.icon-arrow-next-1    { background-image: url("@/themes/light/images/arrow/next.svg");      }

.icon-access-private        { background-image: url("@/themes/light/images/access/private.svg");      }
.icon-access-private-hover { background-image: url("@/themes/light/images/access/private-hover.svg"); }
.icon-access-limited        { background-image: url("@/themes/light/images/access/limited.svg");      }
.icon-access-limited-hover { background-image: url("@/themes/light/images/access/limited-hover.svg"); }

.icon-search { background-image: url("@/themes/light/images/icons/search.svg"); }

.icon-add           { background-image: url("@/themes/light/images/icons/add.svg");           }
.icon-add-highlight { background-image: url("@/themes/light/images/icons/add-highlight.svg"); }

.icon-filter       { background-image: url("@/themes/light/images/icons/filter.svg");       }
.icon-filter-hover { background-image: url("@/themes/light/images/icons/filter-hover.svg"); }

.icon-finish       { background-image: url("@/themes/light/images/icons/finish.svg");       }
.icon-finish-hover { background-image: url("@/themes/light/images/icons/finish-hover.svg"); }

.icon-launch       { background-image: url("@/themes/light/images/icons/launch.svg");       }
.icon-launch-hover { background-image: url("@/themes/light/images/icons/launch-hover.svg"); }

.icon-play       { background-image: url("@/themes/light/images/icons/play.svg");       }
.icon-play-soft  { background-image: url("@/themes/light/images/icons/play-soft.svg");  }
.icon-pause      { background-image: url("@/themes/light/images/icons/pause.svg");      }
.icon-pause-soft { background-image: url("@/themes/light/images/icons/pause-soft.svg"); }

.icon-period       { background-image: url("@/themes/light/images/icons/period.svg");       }
.icon-period-hover { background-image: url("@/themes/light/images/icons/period-hover.svg"); }

.icon-idea       { background-image: url("@/themes/light/images/icons/idea.svg");       }
.icon-idea-hover { background-image: url("@/themes/light/images/icons/idea-hover.svg"); }

.icon-folder       { background-image: url("@/themes/light/images/icons/folder.svg");       }
.icon-folder-hover { background-image: url("@/themes/light/images/icons/folder-hover.svg"); }

.icon-key { background-image: url("@/themes/light/images/icons/key.svg"); }

.icon-work       { background-image: url("@/themes/light/images/icons/work.svg");       }
.icon-work-hover { background-image: url("@/themes/light/images/icons/work-hover.svg"); }

.icon-deadline       { background-image: url("@/themes/light/images/icons/deadline.svg");       }
.icon-deadline-hover { background-image: url("@/themes/light/images/icons/deadline-hover.svg"); }

.icon-calendar       { background-image: url("@/themes/light/images/icons/calendar.svg");       }
.icon-calendar-hover { background-image: url("@/themes/light/images/icons/calendar-hover.svg"); }

.icon-sort       { background-image: url("@/themes/light/images/icons/sort.svg");       }
.icon-sort-hover { background-image: url("@/themes/light/images/icons/sort-hover.svg"); }

.icon-progress       { background-image: url("@/themes/light/images/icons/progress.svg");       }
.icon-progress-hover { background-image: url("@/themes/light/images/icons/progress-hover.svg"); }

.icon-schema       { background-image: url("@/themes/light/images/icons/schema.svg");       }
.icon-schema-hover { background-image: url("@/themes/light/images/icons/schema-hover.svg"); }

.icon-structure       { background-image: url("@/themes/light/images/icons/structure.svg");       }
.icon-structure-hover { background-image: url("@/themes/light/images/icons/structure-hover.svg"); }

.icon-empty-folder       { background-image: url("@/themes/light/images/icons/empty-folder.svg");       }
.icon-empty-folder-hover { background-image: url("@/themes/light/images/icons/empty-folder-hover.svg"); }

.icon-close { background-image: url("@/themes/light/images/icons/close.svg"); }

.icon-plus       { background-image: url("@/themes/light/images/icons/plus.svg");       }
.icon-plus-hover { background-image: url("@/themes/light/images/icons/plus-hover.svg"); }

.icon-minus       { background-image: url("@/themes/light/images/icons/minus.svg");       }
.icon-minus-hover { background-image: url("@/themes/light/images/icons/minus-hover.svg"); }

.icon-timer       { background-image: url("@/themes/light/images/icons/timer.svg");       }
.icon-timer-hover { background-image: url("@/themes/light/images/icons/timer-hover.svg"); }




.icon-hover-dark {
  filter: invert(83%) sepia(0%) saturate(48%) hue-rotate(151deg) brightness(103%) contrast(78%);
  &:hover { filter: none; }
}

.icon-hover-white {
  filter: invert(83%) sepia(0%) saturate(48%) hue-rotate(151deg) brightness(150%) contrast(78%);
  &:hover { filter: none; }
}

.icon-add-event {
  background-image: url("@/themes/light/images/icon-add-event.svg");
}

.icon-edit {
  background-image: url("@/themes/light/images/icon-edit.svg");
}


.icon-attach {
  background-image: url("@/themes/light/images/icon-attach.svg");
}

.icon-approve {
  background-image: url("@/themes/light/images/icon-approve.svg");
}

.icon-arrow-up {
  background-image: url("@/themes/light/images/icon-arrow-up.svg");
}

.icon-arrow-down {
  background-image: url("@/themes/light/images/icon-arrow-down.svg");
}

.icon-arrow-left {
  background-image: url("@/themes/light/images/icon-arrow-left.svg");
}

.icon-arrow-right {
  background-image: url("@/themes/light/images/icon-arrow-right.svg");
}

.icon-arrow-back {
  background-image: url("@/themes/light/images/icon-arrow-back.svg");
}

.icon-arrow-next {
  background-image: url("@/themes/light/images/icon-arrow-next.svg");
}

.icon-back {
  background-image: url("@/themes/light/images/icon-back.svg");
}


.icon-clear {
  background-image: url("@/themes/light/images/icon-clear.svg");
}

.icon-cancel {
  background-image: url("@/themes/light/images/icon-cancel.svg");
}


.icon-exclamation {
  background-image: url("@/themes/light/images/icon-exclamation.svg");
}

.icon-move {
  background-image: url("@/themes/light/images/icon-move.svg");
}

.icon-project {
  background-image: url("@/themes/light/images/icon-project.svg");
}

.icon-remove {
  background-image: url("@/themes/light/images/icon-remove.svg");
}




.icon-save {
  background-image: url("@/themes/light/images/icon-save.svg");
}

.icon-smile-happy {
  background-image: url("@/themes/light/images/icon-smile-happy.svg");
}

.icon-smile-unhappy {
  background-image: url("@/themes/light/images/icon-smile-unhappy.svg");
}


.icon-snapshot {
  background-image: url("@/themes/light/images/icon-snapshot.svg");
}



.icon-risk {
  background-image: url("@/themes/light/images/icon-risk.svg");
}

.icon-term {
  background-image: url("@/themes/light/images/icon-term.svg");
}

.icon-design {
  background-image: url("@/themes/light/images/icon-design.svg");
}

.icon-info {
  background-image: url("@/themes/light/images/icon-info.svg");
}

.icon-hierarchy {
  background-image: url("@/themes/light/images/icon-hierarchy.svg");
}

.icon-sibling {
  background-image: url("@/themes/light/images/icon-sibling.svg");
}

.icon-trash {
  background-image: url("@/themes/light/images/icon-trash.svg");
}


.picker-question {
  background-image: url("@/themes/light/images/picker-question.png");
}

.picker-close {
  background-image: url("@/themes/light/images/picker-close.png");
}