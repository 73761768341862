.card {
  &:focus {
    outline: none;
  }

  &[data-focused]  {
    outline: none;
    border-color: $color-border-highlight;
  }

  &.selected {
    // border-color: $color-border-highlight;
    background-color: $color-base-highlight-shadow;
  }

  textarea {
    &:focus,
    &:focus-visible {
      border-color: $color-border-highlight;
      outline: none;
    }
  }

  &.slave {
    &[data-focused] {
      border-color: $color-border-highlight-slave;
    }

    &.selected {
      background-color: $color-base-highlight-slave-shadow;
    }

    textarea {
      &:focus,
      &:focus-visible {
        border-color: $color-border-highlight-slave;
      }
    }
  }

  .actions {
    .menu,
    .launch,
    .default {
      display: none;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid;
      border-color: $color-border-neutral;
      background-size: auto;
      background-color: $color-base-neutral;
      cursor: pointer;

      &:hover {
        border-color: $color-border-soft;
      }
    }

    .menu-dropdown,
    .launch-dropdown {
      &:focus { outline: none; }
    }

    .menu-dropdown .menu-dropdown-item {
      &[data-context-active] {
        & > .menu-dropdown-item-content {
          background: #EEEEEE;
        }
      }
    }

    .launch-dropdown .launch-dropdown-item {
      &[data-process-active]  {
        background: #EEEEEE;
      }
    }
  }

  &:hover {
    .actions {
      .menu,
      .launch,
      .default {
        display: flex;
      }
    }
  }
}
































.cards {
  display: flex;
  position: relative;
  width: 100%;
  // flex-direction: column;
  border: 1px solid;
  border-color: $color-border-soft;
  border-radius: $border-radius-primary;
  font-size: $font-text-secondary;
  color: $color-font-dark;
  text-decoration: none;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: $font-text;
    border-radius: $border-radius-primary $border-radius-primary 0 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 0 0 $border-radius-primary $border-radius-primary;
  }

  .launch, .finish, .period, .create, .shipped {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid;
    border-color: $color-border-neutral;
    background-size: auto;
    background-color: $color-base-neutral;
    cursor: pointer;

    &:hover {
      border-color: $color-border-soft;
    }
  }

  &.simple {
    &[data-focused]  {
      outline: none;
      border-color: $color-border-highlight;
    }

    &:focus {
      outline: none;
      border-color: $color-border-highlight;
    }

    .header {
      background-color: $color-base-soft;
    }
  }

  &.simple.slave {
    &[data-focused] {
      outline: none;
      border-color: $color-border-highlight-slave;
      background: $color-base-highlight-slave-shadow;
    }

    &:focus {
      outline: none;
      border-color: $color-border-highlight-slave;
      background: $color-base-highlight-slave-shadow;
    }

    &.placeholder {
      .header {
        border-radius: $border-radius-primary;
      }
    }
  }

  &.main {
    &[data-focused]  {
      outline: none;
      border-color: $color-border-highlight;
    }

    &:focus {
      outline: none;
      border-color: $color-border-highlight;
    }

    .header {
      background-color: $color-base-soft;
    }
  }

  &.selected {
    border-color: $color-border-highlight-slave;

    &[data-focused] {
      outline: none;
      border-color: $color-border-highlight;
    }

    .header {
      background-color: $color-base-highlight-slave;
      color: $color-font-neutral;
    }

    .content {
      background-color: $color-base-highlight-slave;
      color: $color-font-neutral;
    }

    .launch, .finish, .period, .create, .shipped {
      border-color: $color-border-neutral;
    }

    &.main {
      border-color: $color-border-highlight;

      .header {
        background-color: $color-base-highlight;
      }

      .content {
        background-color: $color-base-highlight;
      }
    }
  }

  &.compound {
    margin: 5px;
    padding: 0px;

    .left {
      padding: 15px 10px;
    }
  
    .right {
      display: flex;
      align-items: center;
      padding: 15px 10px;
      border-radius: 0 $border-radius-primary $border-radius-primary 0;
      color: $color-font-light;
      background-color: $color-base-dark;
    }
  }

  &.actions {
    padding-right: 35px;

    .icon-close {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 24px;
      height: 24px;
    }
  }

  &.border-top {
    border: none;
    border-top: 1px solid;
  }

  &.dark {
    border-color: $color-border-dark;
  }

  &.light {
    border-color: $color-border-light;
  }

  &.muted {
    filter: invert(0%) sepia(0%) saturate(48%) hue-rotate(151deg) brightness(103%) contrast(78%);
  }

  &.alarm {
    .header {
      border-color: $color-border-denial;

      .title,.id {
        color: $color-font-denial;
      }
    }
  }
}