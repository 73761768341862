.path {
  display: flex;
  word-break: break-all;
  flex-wrap: wrap;

  .disable {
    color: $color-font-soft;
    cursor: text;

    &::after{
      display: inline-block;
      padding-right: 4px;
      padding-left: 4px;
      content: "/";
    }
  }

  .member {
    color: $color-font-soft;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: $color-font-highlight;
      text-decoration: underline;
    }

    &::after{
      display: inline-block;
      padding-right: 4px;
      padding-left: 4px;
      content: "/";
    }
  }
}

.selected {
  .path {
    .member, .disable {
      color: $color-font-neutral;

      &:hover {
        color: $color-font-neutral;
      }
    }
  }
}