
  .landing {
    section.reviews {
      padding: 90px 0px;
      background: #70BD63;

      .container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > div {
          margin-top: 60px;
        }
      }

      .survey {
        cursor: pointer;
        color: #68788D;
        background: #FFF;
        border-radius: 10px;
        position: relative;
        margin: 120px 20px 0px 20px;
        padding: 80px 20px 40px 20px;

        .avatar {
          width: 128px;
          height: 128px;
          margin-top: -144px;
          margin-left: auto;
          margin-right: auto;
          border-radius: 64px;

          background-size: 128px 128px;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .review {
          padding-top: 30px;
          padding-bottom: 60px;
          text-align: center;
        }

        .person {
          right: 20px;
          bottom: 20px;
          position: absolute;

          text-align: right;
          font-style: italic;
        }

        .avatar-1 {
          background-image: url("@/themes/light/images/landing/reviews-pavel.jpeg");
        }

        .avatar-2 {
          background-image: url("@/themes/light/images/landing/reviews-oskar.png");
        }

        .avatar-3 {
          background-image: url("@/themes/light/images/landing/reviews-timofey.jpeg");
        }
      }

      .survey.active {
        color: #68788D;
        background: #FFF;
      }
    }

    @media (min-width: 576px) {
      section.reviews {
        .survey {
          color: #FFF;
          background: none;
        }
      }
    }

    @media (min-width: 992px) {
      section.reviews {
        .container-fluid {
          flex-direction: row;
        }

        .survey {
          margin-top: 40px;
          width: 33%;
        }
      }
    }
  }
