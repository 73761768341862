textarea {
  width: 100%;

  padding: 0px;

  border: none;
  border-bottom: 1px solid;

  overflow: hidden;
  resize: none;
}