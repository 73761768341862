.min {
  input[type=text],
  input[type=number],
  input[type=password] {
    padding: 4px;
  }
}

input[type=text],
input[type=number],
input[type=password] {
  width: 100%;
  padding: 8px 7px;
  box-sizing: border-box;
  border: 1px solid $color-border-light;
  border-radius: $border-radius-header;
  font-size: $font-text;
  color: $color-font-dark;

  &:focus,
  &:focus-visible {
    border-color: $color-border-highlight;
    outline: none;
  }

  &.failure {
    border-color: $color-border-denial;
  }

  &.action {
    padding-right: 30px;
  }

  &::placeholder {
    color: $color-font-light;
  }
}