
  .landing {
    section.contacts {
      color: #FFF;
      height: 220px;
      padding: 40px 15px;
      background: #3D4651;

      .email {
        margin-top: 40px;
        font-size: 112.5%;
      }

      .phone {
        margin-top: 20px;
        font-size: 112.5%;
      }

      .right {
        color: #E5E5E5;
        font-size: 75%;
        margin-top: 25px;
        text-align: right;
        line-height: 1.125;
      }
    }
  }
