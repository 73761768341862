.accordion {
  .accordion-header {
    &:before {
      display: flex;
      align-items: center;
      width: 12px;
      margin-right: 13px;
      background-image: url("@/themes/light/images/arrow/right.svg");
      background-repeat: no-repeat;
      background-position: center;
      content: "";
      cursor: pointer;
    }

    hr {
      height: 1px;
      flex-grow: 1;
      margin-left: 20px;
      margin-right: 20px;
      padding: 0px 10px;
      border: none;
      background-color: $color-base-dark;
    }
  }

  .accordion-content {
    display: none;

    &.sticky {
      display: block;
      overflow: hidden;
      height: 1px;
    }
  }

  &.active {
    .accordion-header{
      &:before {
        display: flex;
        align-items: center;
        width: 12px;
        margin-right: 13px;
        background-image: url("@/themes/light/images/arrow/bottom.svg");
        background-repeat: no-repeat;
        background-position: center;
        content: "";
        cursor: pointer;
      }
    }

    .accordion-content {
      display: block;

      &.sticky {
        height: 100%;
      }
    }
  }
}