div.button {
  display: flex;
  min-width: 100px;
  height: 14px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: $border-radius-header;
  font-size: $font-text;
  text-align: center;
  background-color: #fff;
  cursor: pointer;

  &.primary {
    border: 1px solid $color-border-highlight;
    color: $color-font-highlight;
    &:hover {
      background: $color-base-highlight;
      color: $color-font-neutral;
    }
  }

  &.confirm {
    border: 1px solid $color-border-selection;
    color: $color-font-selection;
    &:hover {
      background: $color-base-selection;
      color: $color-font-neutral;
    }
  }

  &.cancel {
    border: 1px solid $color-border-denial;
    color: $color-font-denial;
    &:hover {
      background: $color-base-denial;
      color: $color-font-neutral;
    }
  }

  &.disabled {
    cursor: default;
  }

  &.status-neutral {
    background: $color-base-neutral;
    border-color: $color-border-highlight;
    color: $color-font-highlight;
    &.hover {
      &:hover {
        background: $color-base-highlight;
        color: $color-font-neutral;
      }
    }
  }

  &.status-success {
    background: $color-base-neutral;
    border-color: $color-border-selection;
    color: $color-font-selection;
    &.hover {
      &:hover {
        background: $color-base-selection;
        color: $color-font-neutral;
      }
    }
  }

  &.status-completed {
    background: $color-base-selection;
    color: $color-font-neutral;
    &.hover {
      &:hover {
        background: $color-base-selection;
      }
    }
  }

  &.status-denial {
    background: $color-denial-soft;
    color: $color-font-neutral;
    &.hover {
      &:hover {
        background: $color-base-denial;
      }
    }
  }

  &.bottom {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &.link {
    color: $color-font-highlight;
    padding: 0px;
    border: none;
    cursor: pointer;
  }

  &.hidden {
    display: none;
  }

  &.small {
    min-width: auto;
    padding: 0px 5px;
  }
}