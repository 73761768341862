body {
  position: relative;

  #reminders {
    position: absolute;
    right: 10px;
    top: 90px;
    max-width: 30%;
    z-index: 1000;

    .reminder {
      display: flex;
      position: relative;
      flex-direction: column;
      margin: 5px;
      padding: 10px;
      padding-right: 30px;
      border: 1px solid #c3e6cb;
      border-radius: 6px;
      background-color: #d4edda;
      color: #155724;
    
      .icon {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
}